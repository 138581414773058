import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
    Alert,
    Snackbar,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Typography,
    Divider
} from '@mui/material';
import { DivFlex } from './styles';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getPartners } from 'services/partners';
import { getTypeDocuments, postContract, getContractPerId, updateContract } from 'services/contracts';
import Toggle from 'react-toggle';

const Contract = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });

    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [partners, setPartners] = useState([]);
    const [contract, setContract] = useState([]);
    const [typeDocuments, setTypeDocuments] = useState([]);
    const [groupsDocuments, setGroupsDocuments] = useState([]);
    const [errorValidate, setErrorValidate] = useState({});
    const isDisabled = params.action === 'view' ? true : false;
    const [groupDocumentsSelected, setGroupDocumentsSelected] = useState(1);

    function viewPerId() {
        params.action &&
            getContractPerId(params.id)
                .then((resp) => {
                    setContract(resp.data?.data);
                    setTypeDocuments(resp.data?.data?.tipo_documento);
                })
                .catch((e) => {
                    setSuccess('');
                    setError(e.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 4000);
                });
    }

    function getAllPartners() {
        getPartners(1, 100).then((resp) => setPartners(resp.data.data));
    }

    function getTypesDocuments() {
        getTypeDocuments(groupDocumentsSelected)
            .then((resp) => {
                setGroupsDocuments(resp.data);
            })
            .catch((e) => {
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    }

    useEffect(() => {
        getTypesDocuments();
        getAllPartners();
        viewPerId();
    }, [params.id]);

    useEffect(() => {
        getTypesDocuments();
    }, [groupDocumentsSelected]);

    return (
        <>
            <Formik
                initialValues={{
                    parceiro_id: contract?.parceiro_id || '',
                    numero: contract?.numero || '',
                    tipo_documento: typeDocuments || []
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    parceiro_id: Yup.number().required('Parceiro é obrigatório'),
                    numero: Yup.string().required('Contrato é obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        setLoading(true);
                        if (params.action === 'edit') {
                            values.tipo_documento = [...new Set(values.tipo_documento)];
                            console.log(values);
                            updateContract(params.id, values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                    setTimeout(() => {
                                        setError('');
                                    }, 3000);
                                });
                        } else {
                            values.ativo = 1;
                            values.tipo_documento = [...new Set(values.tipo_documento)];
                            postContract(values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        resetForm();
                                        setSuccess('');
                                    }, 2000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    if (e.response) {
                                        const { errorValidate, errors, error } = e.response.data;
                                        if (errorValidate) {
                                            setErrorValidate(errorValidate);
                                        }
                                        if (errors) {
                                            const errorMessages = Object.values(errors).flat();
                                            setError(errorMessages[0]);
                                            for (const campo in errors) {
                                                if (errors.hasOwnProperty(campo)) {
                                                    const mensagensErroCampo = errors[campo];
                                                }
                                            }
                                        }
                                        console.log('Status da resposta de erro:', e.response.status);
                                    } else if (e.request) {
                                        console.log('Requisição feita, mas sem resposta');
                                        setError('Erro: Sem resposta do servidor');
                                    } else {
                                        console.log('Erro ao processar a requisição:', e.message);
                                        setError('Ocorreu um erro ao processar a solicitação.');
                                    }
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ margin: 25 }}>
                            <Grid xs={12} md={12} sm={12} container>
                                {params?.action === 'view' ? (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Visualizar Contrato
                                    </h1>
                                ) : params?.action === 'edit' ? (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Editar Contrato
                                    </h1>
                                ) : (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Novo Contrato
                                    </h1>
                                )}

                                <hr style={{ width: '100%', marginTop: 0 }}></hr>
                            </Grid>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched?.numero && errors?.numero) || Boolean(errorValidate?.numero)}
                                                id="outlined-nome-fantasia"
                                                type="text"
                                                label="Número contrato"
                                                value={values?.numero}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="numero"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched?.numero && errors?.numero
                                                        ? errors?.numero
                                                        : errorValidate?.numero
                                                        ? errorValidate?.numero
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={
                                                    Boolean(touched?.parceiro_id && errors?.parceiro_id) ||
                                                    Boolean(errorValidate?.parceiro_id)
                                                }
                                            >
                                                <InputLabel id="partnerSelectLabel">Selecione um Parceiro</InputLabel>
                                                <Select
                                                    labelId="partnerSelectLabel"
                                                    id="parceiro_id"
                                                    name="parceiro_id"
                                                    value={values?.parceiro_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled}
                                                    label="Parceiro"
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione...</em>
                                                    </MenuItem>
                                                    {partners?.map((partner, index) => (
                                                        <MenuItem key={index} value={partner.id}>
                                                            {partner?.nome_fantasia}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {(touched?.parceiro_id && errors?.parceiro_id) || errorValidate?.parceiro_id ? (
                                                    <FormHelperText>
                                                        {touched?.parceiro_id && errors?.parceiro_id
                                                            ? errors?.parceiro_id
                                                            : errorValidate?.parceiro_id
                                                            ? errorValidate?.parceiro_id
                                                            : ''}
                                                    </FormHelperText>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 3 }} />
                                    <Typography variant="h3">Documentação exigida da empresa:</Typography>
                                    <Divider sx={{ mt: 3 }} />
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="grupo_documento">Tipo de Documento</InputLabel>
                                                <Select
                                                    labelId="grupo_documento"
                                                    id="grupo_documento"
                                                    name="grupo_documento"
                                                    value={groupDocumentsSelected}
                                                    onChange={(e) => {
                                                        setGroupDocumentsSelected(e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    label="Grupo de Documento"
                                                >
                                                    <MenuItem value={1}>Fiscal</MenuItem>
                                                    <MenuItem value={8}>Mensal</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {groupsDocuments.map((group) => (
                                        <Grid container spacing={matchDownSM ? 0 : 2}>
                                            <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                                <DivFlex>
                                                    <div style={{ display: 'inline-block' }}>
                                                        <Toggle
                                                            checked={values?.tipo_documento?.find((id) => id === group.id)}
                                                            disabled={isDisabled}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                const exists = values?.tipo_documento?.find((id) => id == group.id);
                                                                if (checked) {
                                                                    if (!exists)
                                                                        setFieldValue('tipo_documento', [
                                                                            ...values?.tipo_documento,
                                                                            group.id
                                                                        ]);
                                                                } else {
                                                                    setFieldValue(
                                                                        'tipo_documento',
                                                                        values?.tipo_documento?.filter((id) => id != group.id)
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ display: 'inline-block', marginLeft: 10 }}>
                                                        <h3>{group?.nome}</h3>
                                                    </div>
                                                </DivFlex>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/contrato/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Contract;
