import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AnimateButton from 'ui-component/extended/AnimateButton';
import TextField from '@mui/material/TextField';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FilterIcon from '@mui/icons-material/FilterAlt';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import GroupIcon from '@mui/icons-material/Group';
import { MENU_OPEN } from 'store/actions';
import ModalFilter from 'components/Modal/ModalFilter';
import { getContracts, deleteContract, changeStatus } from 'services/contracts';
import { Add } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c0c0c0',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));
export default function GridContracts() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const page = useSelector((state) => state.contracts.page);
    const nameContract = useSelector((state) => state.contracts.nameContract);
    const rowsPerPage = useSelector((state) => state.contracts.rowsPerPage);
    const [contracts, setContracts] = useState([]);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [idDestroyContract, setIdDestroyContract] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyContract(idDestroy);
        setOpenModal(true);
    };
    const handleCloseModal = () => setOpenModal(false);
    const handleCloseModalFilter = () => setOpenModalFilter(false);
    const withLink = (to, children) => <Link to={to}>{children}</Link>;

    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_CONTRACT', payload: newPage });
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_CONTRACT', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_CONTRACT', payload: 0 });
    };

    function getAllContracts() {
        getContracts(1).then((resp) => setContracts(resp.data));
    }
    const deleteContractById = () => {
        handleCloseModal();
        deleteContract(idDestroyContract)
            .then((resp) => {
                getAllContracts();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyContract('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };
    const actions = [
        { icon: withLink('/novo_contrato', <Add />), name: 'Novo Contrato' },
        { icon: withLink('#/', <FilterIcon />), name: 'Filtros', operation: 'filtros' }
    ];

    const changeStatusActive = (contract_id) => {
        changeStatus(contract_id)
            .then(() => getAllContracts())
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getAllContracts();
    }, [page, rowsPerPage]);

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'usuarios');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'usuarios' });
        }
    }, []);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Contrato"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir contrato?"
                onDelete={deleteContractById}
            />
            <ModalFilter
                open={openModalFilter}
                title="Filtros"
                handleClose={handleCloseModalFilter}
                content={
                    <>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                <TextField
                                    fullWidth
                                    id="outlined-nameContract"
                                    type="text"
                                    label="Número do contrato"
                                    value={nameContract}
                                    onChange={(e) => dispatch({ type: 'SET_NAMECONTRACT_CONTRACT_FILTER', payload: e.target.value })}
                                    name="nameContract"
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="error"
                                            onClick={(e) => [
                                                dispatch({ type: 'SET_CLEAR_CONTRACT_FILTER' }),
                                                setOpenModalFilter(false),
                                                getAllContracts('', '')
                                            ]}
                                        >
                                            Limpar Busca
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => [getAllContracts(), setOpenModalFilter(false)]}
                                        >
                                            Buscar
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                }
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <h1
                    style={{
                        font: 'normal normal bold 45px/54px',
                        letterSpacing: '0px',
                        color: 'var(--unnamed-color-015641)',
                        color: 'black',
                        opacity: 1
                    }}
                >
                    <GroupIcon /> Contratos
                </h1>
                <hr style={{ width: '100%', marginTop: 0 }}></hr>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3
                        style={{
                            font: 'normal normal 300 18px/22px',
                            letterSpacing: '0px',
                            color: 'black',
                            opacity: 1
                        }}
                    >
                        Gerencie seus contratos
                    </h3>
                    <Button
                        endIcon={<FilterIcon />}
                        onClick={() => setOpenModalFilter(true)}
                        variant="contained"
                        style={{
                            width: '70px',
                            height: '30px',
                            padding: '6px',
                            fontSize: '12px',
                            marginTop: '10px',
                            backgroundColor: '#008B8B'
                        }}
                    >
                        Filtros
                    </Button>
                </div>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '10px 0px 0px 0px' }}>
                                Número
                            </StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>Parceiro</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black' }}>Status</StyledTableCell>
                            <StyledTableCell style={{ backgroundColor: '#DCDCDC', color: 'black', borderRadius: '0px 10px 0px 0px' }}>
                                Ações
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contracts.data &&
                            contracts.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell style={{ color: 'black' }}>{row.numero}</StyledTableCell>
                                    <StyledTableCell style={{ color: 'black' }}>{row.parceiro}</StyledTableCell>
                                    <StyledTableCell>
                                        <Toggle
                                            id={`${row.id}`}
                                            checked={row.ativo === 1 ? true : false}
                                            onChange={() => changeStatusActive(row.id)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div style={{ display: 'flex', marginTop: '9px' }}>
                                            <Tooltip title="Visualizar">
                                                <IconButton onClick={() => navigate({ pathname: `/contrato/${row.id}/view` })}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => navigate({ pathname: `/contrato/${row.id}/edit` })}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir">
                                                <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={4}
                                count={contracts.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'absolute',
                    top: '90%',
                    position: 'fixed',
                    right: 50,
                    zIndex: theme.zIndex.speedDial
                }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => (action.operation === 'filtros' ? [e.stopPropagation(), setOpenModalFilter(true)] : '')}
                    />
                ))}
            </SpeedDial>
        </>
    );
}
